import { mapState } from 'vuex'
import Breakpoints from '~/utils/breakpoints.js'

export default {
  props: {
    isTransparent: {
      type: Boolean,
      default: false
    },
    showMenuOptions: {
      type: Boolean,
      default: true
    }
  },
  mixins: [Breakpoints],
  components: {
    Dropdown: () => import('~/components/commom/dropdown/index'),
    ButtonBlock: () => import('~/components/commom/button-block/index')
  },
  data() {
    return {
      isHidrated: false,
      currentLocale: this.$i18n.locale,
      menuMobileIsOpen: false
    }
  },
  mounted() {
    this.isHidrated = true
  },
  computed: {
    ...mapState(['auth']),
    showHamburguer() {
      return (
        ((this.$breakpoint.lgAndDown && this.showMenuOptions) ||
          (this.auth.isLoggedIn && this.showMenuOptions)) &&
        this.isHidrated
      )
    },
    userInitials() {
      if (!Object.prototype.hasOwnProperty.call(this.auth.user, 'firstName')) {
        return ''
      } else {
        return `${this.auth.user.firstName} ${this.auth.user.lastName}`
          .split(' ')
          .map((name) => name[0].toUpperCase())
          .join()
          .replace(',', '')
      }
    },
    headerClasses() {
      return {
        'top-header--transparent': this.isTransparent && !this.auth.isLoggedIn,
        'top-header--logged-in': this.auth.isLoggedIn
      }
    },
    currentLocaleName() {
      return this.$i18n.locales.filter(
        (locale) => locale.code === this.$store.state.i18n.locale
      )[0].name
    },
    activeLanguages() {
      return this.$i18n.locales.map((locale) => {
        return {
          value: locale.code,
          label: locale.name,
          svg: require(`~/static/images/icons/flags/${locale.code}.svg?include`)
        }
      })
    }
  },
  methods: {
    async logout(event) {
      event.preventDefault()
      await this.revokeToken()
      this.removeAcessCookie()
      window.location.reload()
    },
    revokeToken() {
      return this.$axios.get('/user/token/revoke')
    },
    removeAcessCookie() {
      this.$cookies.remove('access_token')
    },
    mobileLocaleClasses(localeName) {
      return {
        'menu-mobile__languages__entry--active':
          localeName === this.currentLocaleName
      }
    },
    removeStylesFromBody() {
      document.body.style.overflow = ''
      document.body.style.position = ''
      document.body.style.height = ''
      document.documentElement.style.overflow = ''
      document.documentElement.style.position = ''
      document.documentElement.style.height = ''
      this.menuMobileIsOpen = false
    }
  },
  watch: {
    menuMobileIsOpen(value) {
      document.body.style.overflow = value ? 'hidden' : ''
      document.body.style.position = value ? 'relative' : ''
      document.body.style.height = value ? '100%' : ''
      document.documentElement.style.overflow = value ? 'hidden' : ''
      document.documentElement.style.position = value ? 'relative' : ''
      document.documentElement.style.height = value ? '100%' : ''
    },
    currentLocale(value) {
      if (this.menuMobileIsOpen) {
        this.removeStylesFromBody()
      }
      this.$router.push({
        path: this.switchLocalePath(value)
      })
    }
  }
}
